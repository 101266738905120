import http from "./http-common";

const sendEmail = (name, phone, email, message) => {
    return http.post(`/emailjs/sendEmail`, {
        name, 
        phone,
        email, 
        message
    });
};

const EmailService = {
    sendEmail
}

export default EmailService;