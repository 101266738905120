import http from "./http-common";

const sendMessage = (name, phone, email, message) => {
    return http.post(`/telegram/sendMessage`, {
        name,
        phone,
        email,
        message
    });
};

const analytics = (data) => {
    return http.post(`/telegram/analytics`, {
        data
    });
};

const TelegramService = {
    sendMessage,
    analytics
}

export default TelegramService;