export const email = "egaydarova@icali.es";
export const phone = "+34 603 025 915";
export const facebook = "https://www.facebook.com/profile.php?id=100090789190076";
export const instagram = "https://www.instagram.com/eghabogada";
export const telegram = "https://t.me/emiligh";
export const whatsapp = "https://wa.me/message/EKNQX6OJ2S26C1?src=qr";
export const viber = "viber://contact?number=%2B34603025915";
export const address = "Calle Pardo Gimeno n.º 51, local 33-34, 03007 Alicante";
export const addressGMaps = "https://goo.gl/maps/ZsPmSdZeNTLfuVdBA";
export const creator = "2023 Designed by";
export const partners  = [
    {
        "logo": "img/partners/logo_bgescc.png",
        "url": "https://bgescc.com/"
    }
];