import http from "./http-common";

const getAll = (language) => {
    return http.get(`/articles/${language}`);
};

const getCategories = (language) => {
    return http.get(`/articles/categories/${language}`);
}

const ArticlesService = {
    getAll,
    getCategories
}

export default ArticlesService;