import { useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import TelegramService from "../services/telegramService.js";
import EmailService from "../services/emailService";
import * as Constants from "../data/constants";

const initialState = {
  name: "",
  phone: "",
  email: "",
  message: "",
};
export const Contact = () => {
  const [{ name, phone, email, message }, setState] = useState(initialState);
  const { t } = useTranslation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let notified = false;

    try {
      await EmailService.sendEmail(name, phone, email, message)
      .then(response => {
        if (response.status === 200) {
          notified = true;
        }
      });
    }
    catch (e) {
      console.log("Something went wrong...");
    }
  
    try {
      await TelegramService.sendMessage(name, phone, email, message)
      .then(response => {
        if (response.status === 200) {
          notified = true;
        }
      });
    }
    catch (e) {
      console.log("Something went wrong...");
    }
    
    if (notified) {
      alert(t('Contact.SendMessageAlertOK'));
    }
    else {
      alert(t('Contact.SendMessageAlertKO'));
    }
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>{t("Contact.ContactTitle")}</h2>
                <p>{t("Contact.ContactSubTitle")}</p>
              </div>
              <form name="sendMessage" onSubmit={handleSubmit}>
              <div className="row">
                  <div className="col-md-12 col-xs-12">
                    <div className="form-group">
                      <input
                        type={"text"}
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder={t("Contact.PHName")}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type={"text"}
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder={t("Contact.PHPhone")}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder={t("Contact.PHEmail")}
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder={t("Contact.PHMessage")}
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className="help-block text-danger"></p>
                </div>
                <div id="success"></div>
                <button id="btnSubmit" type="submit" className="btn btn-custom btn-lg">{t("Contact.SendMessageButton")}</button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>{t('Contact.CI')}</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> {t('Contact.PHPAddress')}
                </span>
                <a className="goldenColor" href={Constants.addressGMaps} target="_blank" rel="noreferrer">{Constants.address}</a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> {t('Contact.PHPhone')}
                </span>{" "}
                <a className="goldenColor" href={"tel:" + Constants.phone}>{Constants.phone}</a>
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-regular fa-envelope"></i> {t('Contact.PHEmail')}
                </span>{" "}
                <a className="goldenColor" href={"mailto:" + Constants.email}>{Constants.email}</a>
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={Constants.facebook} target="_blank" rel="noreferrer">
                    <i className="fa fa-brands fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={Constants.instagram} target="_blank" rel="noreferrer">
                    <i className="fa fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={Constants.whatsapp} target="_blank" rel="noreferrer">
                    <i className="fa fa-brands fa-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a href={Constants.telegram} target="_blank" rel="noreferrer">
                    <i className="fa fa-brands fa-telegram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={Constants.viber} target="_blank" rel="noreferrer">
                    <i className="fa fa-brands fa-viber"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="partners">
        <div className="container text-center">
          <div id="partners" className="container">
            <div className="section-title">
              <h2>{t("Footer.title")}</h2>
              <p>{t("Footer.paragraph")}</p>          
            </div>
            
            <div className="row">
              {Constants.partners.map((d, i) => (
                <a key={i} className="partner" href={d.url} target="_blank" rel="noreferrer">
                  <img src={d.logo} alt={d.logo}/>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            <a href="/privacy">{t('Privacy.title')}</a>
          </p>
        </div>
        <div className="container text-center">
          <p>
            &copy; {Constants.creator} &nbsp;
            <a href="https://github.com/kikchan"><i className="fa fa-brands fa-github">{"  "}Kikchan</i></a>
          </p>
        </div>
      </div>
    </div>
  );
};
