import React, { useState, useEffect } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Navigation } from "./components/navigation";
import ArticlesService from "./services/articleService";
import SmoothScroll from "smooth-scroll";
import ReactMarkdown from 'react-markdown';
import "./App.css";
import * as Constants from "./data/constants";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const Blog = () => {  
  const { t } = useTranslation();
  const [articles, setArticles] = useState([]);
  const [articlesBackup, setArticlesBackup] = useState([]);
  const [categories, setCategories] = useState([]);
  
  useEffect(() => {
    retrieveArticles();
    retrieveCategories();

    i18next.on('languageChanged', retrieveArticles);
    i18next.on('languageChanged', retrieveCategories);
  }, []);

  const retrieveArticles = () => {
    const language = sessionStorage.getItem("language") ? sessionStorage.getItem("language") : "ES";

    ArticlesService.getAll(language)
      .then((res) => {
        setArticles(res.data);
        setArticlesBackup(res.data);
      })
      .catch(e => {
        console.log(e);
      });
  };
  
  const retrieveCategories = () => {
    const language = sessionStorage.getItem("language") ? sessionStorage.getItem("language") : "ES";
        
    ArticlesService.getCategories(language)
      .then((res) => {
        setCategories(res.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  function filterOutCategories(category) {
    setArticles(articlesBackup.filter(a => a.CATEGORY === category));
  }

  function resetCategories() {
    setArticles(articlesBackup);
  }

  return (
    <div>
      <Navigation />
      
      <div id="blog" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>{t("Blog.title")}</h2>
          </div>
          
            <section className="blog-posts grid-system">
              <div className="container">
                <div className="row">
                  
                  {/* Articles */}
                  <div className="col-lg-8">
                    <div className="all-blog-posts">
                      <div className="row">
                        {articles && articles.map((d, i) => (
                          <div key={i} className="col-lg-6">
                            <div className="blog-post">
                              <div className="blog-thumb opaque">
                                <a href="/blog#" data-toggle="modal" data-target={"#myModal_" + d.ID} className="pointer">
                                  <img src={d.PREVIEW_IMAGE} alt={"img_" + d.ID}/>
                                </a>
                              </div>
                              <div className="down-content">
                                <span>{d.CATEGORY}</span>
                                <a href="/blog#" data-toggle="modal" data-target={"#myModal_" + d.ID} className="pointer"><h4>{d.TITLE}</h4></a>
                                <ul className="post-info darkBlueColor">
                                  <li>{t('Blog.author') + d.AUTHOR}</li>
                                  <li>{t('Blog.published') + new Date(d.CREATION_DATE).toLocaleString()}</li>
                                </ul>
                                <p className="darkBlueColor">{d.CONTENT_DEMO}</p>

                                {/* Modal */}
                                <div className="modal fade" id={"myModal_" + d.ID} role="dialog">
                                  <div className="modal-dialog">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                                      </div>
                                      <div className="modal-body">
                                        <div className="all-blog-posts">
                                          <div className="row">
                                            <div className="col-lg-12">
                                              <div className="blog-post">
                                                <div className="blog-thumb">
                                                  <img className="headerImage" src={d.HEADER_IMAGE} alt=""/>
                                                </div>
                                                <div className="down-content">
                                                  <span>{d.CATEGORY}</span>
                                                  <h4>{d.TITLE}</h4>
                                                  <ul className="post-info headerTitle darkBlueColor">
                                                    <li>{t('Blog.author') + d.AUTHOR}</li>
                                                    <li>{t('Blog.published') + new Date(d.CREATION_DATE).toLocaleString()}</li>
                                                    {d.MODIFICATION_DATE ? 
                                                      <li>{t('Blog.modified') + new Date(d.CREATION_DATE).toDateString()}</li>
                                                    :
                                                      ""
                                                    }
                                                  </ul>
                                                  <div>
                                                    <ReactMarkdown className="markdownContent darkBlueColor" children={d.CONTENT} />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="modal-footer">
                                        <button type="button" id="btnClose" className="btn btn-custom" data-dismiss="modal">{t('Modal.close')}</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* Sidebar */}
                  <div className="col-lg-4">
                    <div className="sidebar">
                      <div className="row">
                        {/* Recent posts */}
                        <div className="col-lg-12">
                          <div className="sidebar-item categories">
                            <div className="sidebar-heading">
                              <h2>{t('Blog.recentPosts')}</h2>
                            </div>
                            <div className="content">
                              <ul>
                                {articlesBackup && articlesBackup.map((d, i) => (
                                  i < 3 ?
                                  <li key={i}>
                                    <a href="/blog#" data-toggle="modal" data-target={"#myModal_" + d.ID} className="pointer">
                                      <h5>- {d.TITLE}</h5>
                                    </a>
                                  </li>
                                  : null                                  
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>

                        {/* Categories */}
                        <div className="col-lg-12">
                          <div className="sidebar-item categories">
                            <div className="sidebar-heading">
                              <h2>{t('Blog.categories')}</h2>
                            </div>
                            <div className="content">
                              <ul>
                                {categories.length > 0 ?
                                  <li key="0"><a href="/blog#" onClick={() => resetCategories()}>- {t('Blog.allCategories')}</a></li>
                                  : null
                                }

                                {categories && categories.map((d, i) => (
                                  <li key={i}><a href="/blog#" onClick={() => filterOutCategories(d)}>- {d}</a></li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
        </div>
      </div>

      <div id="footer" className="footer navbar-fixed-bottom">
        <div className="container text-center">
          <p>
            <a href="/privacy">{t('Privacy.title')}</a>
          </p>
        </div>
        <div className="container text-center">
          <p>
            &copy; {Constants.creator} &nbsp;
            <a href="https://github.com/kikchan"><i className="fa fa-brands fa-github">{"  "}Kikchan</i></a>
          </p>
        </div>
      </div>  
    </div>
  );
};

export default Blog;